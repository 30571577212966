import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/layout/Seo';
import {  IconMail2, IconPhone, IconPlace } from '../components/icons/Icons';
import Map from '../components/common/Map';
import AdditionalForms from '../components/common/AdditionalForms';
import HowItWorks from '../components/common/HowItWorks';
import { graphql, useStaticQuery } from "gatsby"
import ProductGrid from "../components/products/ProductGrid"

const CartierWatchesPage = () => {
    const { preferedWatches, cartierWatches } = useStaticQuery(graphql`
      {
        preferedWatches: allStrapiProduct(
          filter: {

            sku: {
              in: ["W527840", "W527592", "W527426", "W527773", "W527779", "W527754", "W527742"]
            }
            brand: { eq: "Cartier" }
            item_type: { eq: "Watch" }
          }
        ) {
          nodes {
                name
                productType
                categories {
                    name
                    id
                }
                url_key
                sku
                title
                small_image {
                    name
                    url
                }
                thumbnail {
                    name
                    url
                }
                brand
                model
                price
          }
        }
        cartierWatches: allStrapiProduct(
          filter: {
            sku: {
              nin: ["W527840", "W527592", "W527426", "W527773", "W527779", "W527754", "W527742"]
            }
            brand: { eq: "Cartier" }
            item_type: { eq: "Watch" }
          }
          sort: { fields: stock_date, order: DESC }
        ) {
          nodes {
                name
                productType
                categories {
                    name
                    id
                }
                url_key
                sku
                title
                small_image {
                    name
                    url
                }
                thumbnail {
                    name
                    url
                }
                brand
                model
                price
          }
        }
      }
    `)
    const first7Watches = preferedWatches.nodes.concat(cartierWatches.nodes).slice(0, 7)
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        canonical={"preowned-used-cartier-watches-buying-selling-repairing"}
        title="Buy, Sell, and Repair Preowned Cartier Watches"
        description="Sell Used Jewelry & Luxury Cartier Watches. Top Rated Buyer, Seller of Pre-Owned Cartier. Highest & Immediate Payouts. World Leader Buyer since 1980."
      />

      <h1 className="text-center text-2xl font-black text-link xl:text-3xl padding-bottom: 0.25rem my-10">
        Buying Selling Repairing Preowned Used Cartier Watches Best Prices
      </h1>
      <h2 className="text-center text-xl font-black text-link xl:text-2xl padding-bottom: 0.25rem my-10">
        {" "}
        Popular Pre-Owned Cartier Watches{" "}
      </h2>
      <ProductGrid products={first7Watches} />

      <div className="text-gray-600 text-sm w-10/12 mx-auto max-w-[1366px]">

        Discover the world of Cartier watches at Sell Us Your Jewelry, where our expertise in these
        luxurious timepieces is unmatched. Cartier, often hailed as &quot;the jeweler of kings and
        the king of jewelers,&quot; crafts watches that are not just timekeepers but works of art,
        adorned with exquisite jewels and precious metals. These watches are a testament to refined
        taste and grandeur. Choosing to own a Cartier watch goes beyond a simple purchase; it is an
        investment in a legacy of elegance, ideal for the most prestigious events and formal
        gatherings. Our curated collection of pre-owned Cartier watches offers you the chance to
        buy, sell, repair, or trade-in, helping you find that perfect piece that resonates with your
        style and sophistication.
      </div>

      <HowItWorks className="w-10/12 mx-auto max-w-[1366px]" />

      <AdditionalForms />

      <div className={"container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0"}>
        <section className={"w-full"}>
          <h2 className={"text-center text-4xl uppercase"}>Still have Questions?</h2>
        </section>
        <section className={"w-full"}>
          <p className={"mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5"}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={"flex w-full flex-wrap gap-16 lg:flex-nowrap"}>
          <div
            className={
              "align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPlace />
            </div>
            <div className={"text-3xl uppercase"}>Visit Us</div>
            <div className={"mt-16 text-link"}>9595 Harding Avenue, Bal Harbour Florida 33154</div>
          </div>
          <div
            className={
              "align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPhone />
            </div>
            <div className={"text-3xl uppercase"}>Call Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"tel:+13057706955"}>tel: 305 770 6955</a>
            </div>
          </div>
          <div className={"align-center flex w-full flex-col justify-center text-center lg:w-1/3"}>
            <div className={"mb-8 text-5xl text-accent"}>
              <IconMail2 />
            </div>
            <div className={"text-3xl uppercase"}>Email Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a>
            </div>
          </div>
        </section>
      </div>

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
};

/*
 *
*/
export default CartierWatchesPage;
